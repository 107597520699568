import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e10bc52c = () => interopDefault(import('../pages/agenda/index.vue' /* webpackChunkName: "pages/agenda/index" */))
const _7b400f95 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _6ed9d8c0 = () => interopDefault(import('../pages/downloads.vue' /* webpackChunkName: "pages/downloads" */))
const _47723b65 = () => interopDefault(import('../pages/nieuws/index.vue' /* webpackChunkName: "pages/nieuws/index" */))
const _b94c38a2 = () => interopDefault(import('../pages/zoekresultaten.vue' /* webpackChunkName: "pages/zoekresultaten" */))
const _e47907bc = () => interopDefault(import('../pages/agenda/_slug.vue' /* webpackChunkName: "pages/agenda/_slug" */))
const _45bb9a1d = () => interopDefault(import('../pages/nieuws/_slug.vue' /* webpackChunkName: "pages/nieuws/_slug" */))
const _f99602f2 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6cb3005f = () => interopDefault(import('../pages/*.vue' /* webpackChunkName: "pages/*" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/agenda",
    component: _e10bc52c,
    name: "agenda"
  }, {
    path: "/contact",
    component: _7b400f95,
    name: "contact"
  }, {
    path: "/downloads",
    component: _6ed9d8c0,
    name: "downloads"
  }, {
    path: "/nieuws",
    component: _47723b65,
    name: "nieuws"
  }, {
    path: "/zoekresultaten",
    component: _b94c38a2,
    name: "zoekresultaten"
  }, {
    path: "/agenda/:slug",
    component: _e47907bc,
    name: "agenda-slug"
  }, {
    path: "/nieuws/:slug",
    component: _45bb9a1d,
    name: "nieuws-slug"
  }, {
    path: "/",
    component: _f99602f2,
    name: "index"
  }, {
    path: "/*",
    component: _6cb3005f,
    name: "*"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
